<div class="modal-content">
  <div class="modal-body">
    <div class="add-pro-outer">
      <div class="add-hdr mb-2">
        <div class="heading">
          <h2>Lookup Record</h2>
        </div>
        <div class="add-act">

          <a href="javascript:void(0)" title="" (click)="closeModal()" class="close" data-bs-dismiss="modal"
            aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
        </div>
      </div>
      <div class="px-4 lookup-content">
        <div class="page-hdr-outer">
          <div id="tbl1">
            <div class="grid-con" id="noaction">
              <div class="grid__wrapper wrp-pros" id="lookupSubmissionCarrier">
                <kendo-grid (cellClick)="onCellClick($event)" (filterChange)="filterChange($event)"
                  [filterable]="isFilter" [data]="lookupData" [skip]="skip" [resizable]="true" [filter]="gridFilter"
                  [pageable]="{ pageSizes: true, position: 'top' }" [pageSize]="pageSize" [selectable]="{
                    enabled: true,
                    checkboxOnly: false,
                    mode: 'multiple'
                  }" [pageable]="{ pageSizes: true, position: 'top' }" (pageChange)="carrierPaginate($event)">
                  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                    <div class="flex-div justify-content-between grid-hd">
                      <div class="flex-div">
                        <kendo-textbox placeholder="Search in all columns..." #searchText
                          (change)="searchValue(searchText.value)" [style.width.px]="205"></kendo-textbox><img
                          src="assets/images/search.svg" (click)="onFilter()" alt="" class="ms-2" />
                      </div>
                      <div class="flex-div">
                        <div class="flex-div">
                          <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                          <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                          <kendo-pager-next-buttons></kendo-pager-next-buttons>

                          <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
                          <kendo-grid-messages [pagerItemsPerPage]="'Items per page'">
                          </kendo-grid-messages>
                        </div>
                        <kendo-pager-info class="bd-l"></kendo-pager-info>
                      </div>
                    </div>
                    <button [ngClass]="{ actbtn: isFilter }" (click)="columnFilter(isFilter)" class="filter-button">
                      <img src="assets/images/filter.svg" alt="" class="flt-icon" />
                    </button>
                  </ng-template>
                  <kendo-grid-column field="carrierShortName" title="Carrier Name" [width]="50">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <div class="cr-wd">
                        <p pTooltip="{{ dataItem.carrierShortName }}" tooltipPosition="bottom"
                          tooltipStyleClass="tooltipstyle">{{ dataItem.carrierShortName }}</p>
                      </div>
                    </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column field="submissionEmail" title="Submission Email" [width]="50">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <div class="cr-wd">
                        <p pTooltip="{{ dataItem.submissionEmail }}" tooltipPosition="bottom"
                          tooltipStyleClass="tooltipstyle">{{ dataItem.submissionEmail }}</p>
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="tierName" title="Tier Name" [width]="50">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <div class="cr-wd">
                        <p pTooltip="{{ dataItem.tierName }}" tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">
                          {{ dataItem.tierName }}</p>
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="masterPackageTierId" title="Master Package Tier" [width]="50">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <div class="cr-wd">
                        <p pTooltip="{{ dataItem.masterPackageTierId }}" tooltipPosition="bottom"
                          tooltipStyleClass="tooltipstyle">
                          {{ dataItem.masterPackageTierId }}</p>
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="masterWorkersCompensationTierId" title="Master Workers Compensation Tier"
                    [width]="50">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <div class="cr-wd">
                        <p pTooltip="{{ dataItem.masterWorkersCompensationTierId }}" tooltipPosition="bottom"
                          tooltipStyleClass="tooltipstyle">
                          {{ dataItem.masterWorkersCompensationTierId }}</p>
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid>
              </div>
            </div>
            <div class="btn-blk1 pb-4 text-end">
              <button class="btn btn-outline-primary me-4" (click)="closeModal()">Cancel</button>
              <button class="btn btn-primary " (click)="emitSelectedData()">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>