import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { LookupPaginatorModel } from '../../../shared/lookup-paginator';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { CarriersService } from 'src/app/features/carrier-management/services/carriers-service';
import { CarriersPaginatorModel } from 'src/app/features/carrier-management/shared/carriers-paginator';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-lookup-submissionCarrier',
  templateUrl: './lookup-submissionCarrier.component.html',
  styleUrls: ['./lookup-submissionCarrier.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class LookupSubmissionCarrierComponent implements OnInit, OnDestroy {
  _navigationPath: NavigationPath;
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  _lookUpPaginator: LookupPaginatorModel;
  totalRecords: any;
  rowsPerPageOptions!: any[];
  dividerValue: any;
  outcomeValue: any;
  ClickedRow: any;
  HighlightRow!: Number;
  selectedData: any;
  justClicked: boolean = false;
  doubleClicked: boolean = false;
  public pageSize = 10;
  public buttonCount = 5;
  public sizes = [10, 25, 50, 100, 250, 500];
  public skip = 0;
  isFilter: boolean = false;
  pageNo: number = 1;
  public totalCount = 0;
  searchGrid: string = '';
  carrierList: any;
  carrierList$!: Observable<any>;
  masterCarrierList: any[] = [];
  FinalCarrierList: any = [];
  $searchingText = new Subject<void>();
  _paginator: CarriersPaginatorModel;
  public gridFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  public sort: SortDescriptor[] = [
    {
      field: 'carrierShortName',
      dir: 'asc',
    },
  ];
  serviceResponse$!: Observable<any>;
  @Input() lookupData: any;
  @Output() messageEvent = new EventEmitter<string>();
  unsubscribe$: Subject<boolean> = new Subject();
  wbaId: number = 0;
  FilterBlankcheck: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private _loaderService: LoaderService,
    private _carriersService: CarriersService) {
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this._navigationPath = new NavigationPath();
    this._lookUpPaginator = new LookupPaginatorModel();
    this._paginator = new CarriersPaginatorModel();
    // calling api after 1 sec when entring test in search text
    this.$searchingText.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.getSubmissionCarriersListInfo();
    });
  }

  ngOnInit(): void {
    this.wbaId = parseInt(sessionStorage.getItem(environment.storageVariables.wbaId) || "0");
    this.getSubmissionCarriersListInfo();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  //to calcel the process and back to prospect list screen
  cancel() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  getSubmissionCarriersListInfo() {
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": this.sort[0].dir,
      "sortCol": this.sort[0].field,
      "searchName": this.searchGrid,
      "userId": "",
      "gridFilterJson": this.gridFilter.filters.length > 0 ? JSON.stringify(this.gridFilter) : "",
      "wbaId": this.wbaId
    };
    this._loaderService.show();
    this.carrierList$ = this._carriersService.getSubmissionCarriersList(data);
    this.carrierList$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (resp: any) => {
        if (resp && resp.items) {
          for (var i = 0; i < resp.items.length; i++) {
            if (i == 0) {
              this.carrierList = resp.items[i].carrierList;
            }
          }
          this.masterCarrierList = this.carrierList;
          this._paginator.totalRecords = resp.totalRecord;
          this.lookupData = {
            data: this.masterCarrierList,
            total: resp.totalRecord,
          };
          this._loaderService.hide();
        }
      }
    );
  }
  selctedValue(data: any) {
    this.selectedData = data;
  }

  public onCellClick(e: any) {
    this.selctedValue(e.dataItem)
  }
  emitSelectedData() {
    let data: any;
    data = {
      selectedData: this.selectedData,
      totalRecords: this.selectedData?.totalRecords
    }

    if (this.selectedData) {
      this.messageEvent.emit(data);
    }
    this.closeModal();
  }

  closeModal() {
    this.activeModal.close();
  }

  //searching in grid columns
  public filterChange(filter: CompositeFilterDescriptor): void {

    this.skip = 0;
    this.pageNo = 1;
    this.totalCount = 0;
    this.gridFilter = { ...filter };
    this.$searchingText.next();
  }

  //searching on all columns
  onFilter(): void {
    if (this.searchGrid === "" && !this.FilterBlankcheck) {
      return
    }
    if (this.searchGrid === "" && this.FilterBlankcheck) {
      this.FilterBlankcheck = false;
      this.pageNo = 1;
      this.skip = 0;
      this.totalCount = 0;
      this.getSubmissionCarriersListInfo();
    }
    else {
      this.FilterBlankcheck = true;
      this.pageNo = 1;
      this.skip = 0;
      this.totalCount = 0;
      this.getSubmissionCarriersListInfo();
    }
  }

  searchValue(inputValue: any) {
    this.searchGrid = inputValue;
  }

  carrierPaginate(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.pageNo = (event.take + event.skip) / event.take;
    this.totalCount = this.lookupData.total
    //calling api
    this.getSubmissionCarriersListInfo();
  }

  //To hide column filters
  columnFilter(value: boolean) {
    this.isFilter = !value;
  }
}
